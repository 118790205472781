.footer {
    padding: $ratio8 0;
    background-color: $color-black;

    .wrapper {
        @extend .flex;
        @extend .justify-between;
        @extend .items-center;

        .right-content {
            width: 60%;
            @extend .flex;
            @extend .justify-between;
            @extend .items-center;

            .infos {
                @extend .flex;
                gap: $ratio6;
    
                .info {
    
                    h3 {
                        padding-bottom: $ratio6;
                        font-size: $font-size-24;
                        color: $color-white;
                        text-transform: uppercase;
                    }
                    a {
                        font-size: 16px;
                        color: $color-white;
                        line-height: 1.4;
                    }
                }
            }
    
            .copyright_logo {
                p {
                    padding-bottom: $ratio4;
                    font-size: 16px;
                    color: $color-grey;
                }
            }
        }
    }

    @media screen and (max-width: $size-md-max) {
        .wrapper {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-align-items: flex-start;
	        align-items: flex-start;
            gap: $ratio8;

            .right-content {
                width: 100%;
                -webkit-justify-content: space-between;
                -moz-justify-content: space-between;
                -ms-justify-content: space-between;
                justify-content: space-between;
                -webkit-align-items: flex-end;
	            align-items: flex-end;
            }
        }
    }

    @media screen and (max-width: $size-md-min) {
        .wrapper {
            .right-content {
                .infos {
                    -webkit-flex-wrap: wrap;
                    -moz-flex-wrap: wrap;
                    -ms-flex-wrap: wrap;
                    flex-wrap: wrap;
                }

                .copyright_logo {
                    img {
                        min-width: 200px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 480px) {
        .wrapper {
            .right-content {
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -webkit-flex-direction: column;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-align-items: flex-start;
	            align-items: flex-start;
                gap: $ratio8;
            }
        }
    }
}