// IMPORT ADDONS
//@import '../vendors/bootstrap/css/bootstrap';
//@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
// @import '../vendors/owl-carousel2/owl.carousel';
// @import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';
@import 'utilities/wrapper';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';
@import 'styles/accordions';




/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'modules/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';
//@import 'sections/section-temoignages';

//@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
//@import 'modules/module-slideshow';
//@import 'modules/module-slider-light';
//@import 'modules/module-header';
@import 'modules/module-soumission';



/*******************************************************************************
* COMMON TO ALL PAGES
*/

section.hero {
    height: 800px;
    position: relative;
    z-index: 1;

    .hidden-xs {
        display: block;
    }
    .visible-xs {
        display: none;
    }

    .hero__media {
        height: 100%;
        width: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
    }

    .wrapper {
        padding-top: $size-nav-height;
        position: relative;

        .hero__content {
            padding-top: 10%;
            position: relative;   
            height: 80%;

            @extend .flex;
            @extend .flex-column;
            gap: $ratio4;

            h1 {
                font-size: $font-size-60;
                font-weight: 700;
                color: $color-white;
            }

            p {
                width: 80%;
                color: $color-white;

                &:last-child {
                    width: 60%;
                }
            }
        }
    }

    .phone-number {
        background-color: $color-primary;
        padding: $ratio8 $ratio8 $ratio8 7.5%;
        width: fit-content;
        position: absolute;
        bottom: 0;

        
        .phone-link {
            color: $color-white;
            @extend .flex;
            @extend .items-center;
            gap: $ratio8;
        }

        .phone-infos {
            padding: $ratio2 $ratio3;
            border-left: solid 2px $color-white;
            font-size: $font-size-30;

            .open {
                padding-bottom: $ratio2;
                font-size: 16px;
            }
        }
    }
    .cta-contact {
        background-color: #FFFFFFE8;
        padding: $ratio8 7.5% $ratio8 $ratio8;
        position: absolute;
        bottom: 0;
        right: 0;
        border-top-left-radius: 25px;

        h3 {
            padding-bottom: $ratio6;
        }
    }

    @media screen and (max-width: $size-md-min) {
        .wrapper {
            padding-top: $size-nav-height-mobile;

            .hero__content {
                padding-top: 20%;

                p {
                    width: 100%;

                    &:last-child {
                        width: 100%;
                    }
                }
            }
        }

        .phone-number {
            display: none;
        }
        .cta-contact {
            width: calc(100% - 15%);
            border-radius: 0;
            padding: $ratio8 7.5%;
        }
    }

    @media screen and (max-width: $size-xs-max) {
        .hidden-xs {
            display: none;
        }
        .visible-xs {
            display: block;
        }

        .wrapper {
            padding-top: $size-nav-height-mobile;

            .hero__content {
                padding-top: 20%;

                h1 {
                    font-size: $font-size-50;
                }

                p:first-child {
                    display: none;
                }
            }
        }
    }

    &.services {
        .wrapper {
            height: 95%;
            .hero__content {
                padding: 0;
                @extend .flex;
                @extend .justify-end;
            }
        }
    }
}


.hero.media__only {
    video.hero__media {
        display: block;
        object-fit: cover;
    }
    
    video.hero__media.mobile {
        display: none;
    }
    
    @media screen and (max-width: $size-xs-max) {
        video.hero__media {
            display: none;
        }
        
        video.hero__media.mobile {
            display: block;
        }
    }
}

img.section__media {
    height: 100%;
    width: 100%;
    display: block;
    object-fit: cover;
}
img.section__media.mobile {
    display: none;
}
@media screen and (max-width: $size-xs-max) {

    img.section__media {
        display: none;
    }
    img.section__media.mobile {
        display: block;
    }
}


/*****************************************************/
/* ACCUEIL
/*****************************************************/


.section-02-accueil,
.section-01-services {
    padding: $ratio12 0;
    background-color: $color-white;

    .wrapper {
        display: grid;
        grid-template-columns: 3fr 7fr;
        gap: $ratio12;

        .left {
            @extend .flex;
            @extend .flex-column;
            @extend .justify-center;
            gap: $ratio6;
        }
    }

    @media screen and (max-width: $size-md-max) {
        .wrapper {
            grid-template-columns: 4fr 6fr;
        }
    }

    @media screen and (max-width: $size-md-mid) {
        .wrapper {
            grid-template-columns: 1fr;

            .right {
                img {
                    height: 400px;
                }
            }
        }
    }
}

.section-03-accueil {
    margin-bottom: $ratio10;
    background-color: $color-grey-light;

    .wrapper {
        display: grid;
        grid-template-columns: 6fr 4fr;
        gap: $ratio12;

        .left {
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        .right {
            @extend .flex;
            @extend .flex-column;
            @extend .justify-center;
            gap: $ratio6;

            a:first-child {
                font-weight: 700;
                color: $color-black;
            }
        }
    }

    @media screen and (max-width: $size-md-mid) {
        .wrapper {
            grid-template-columns: 1fr;

            .left {
                img {
                    height: 400px;
                }
            }
        }
    }
}


/*****************************************************/
/* SERVICES
/*****************************************************/

.section-01-services {
    padding: $ratio12 0;

    .wrapper {
        grid-template-columns: 4fr 7fr;
    }

    @media screen and (max-width: $size-md-mid) {
        .wrapper {
            grid-template-columns: 1fr;
        }
    }
}

.section-02-services {
    padding: $ratio10 0;

    .wrapper {
        padding-bottom: $ratio14;

        .spacer {
            margin-top: $ratio4;
            border: none;
            border-bottom: solid 2px $color-primary;
        }
    }
}

.section-03-services {
    padding: $ratio10 0;

    .wrapper {
        width: 100%;
        @extend .flex;
        gap: $ratio4;

        .left {
            background-color: $color-secondary;
            width: 35%;
            padding: $ratio8 7.5%;
            @extend .flex;
            @extend .flex-column;
            @extend .justify-start;
            gap: $ratio6;

            h2 {
                font-weight: 500;
            }

            ul {
                list-style: none;

                li {
                    padding-top: $ratio4;
                    font-weight: 500;
                    @extend .flex;  
                    @extend .items-center;
                    gap: $ratio5;
                }
            }
        }

        .right {
            width: 65%;
        }

        @media screen and (max-width: $size-md-mid) {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;

            .left {
                width: auto;
            }
            .right {
                width: auto;

                img {
                    height: 400px;
                }
            }
        }
    }
}

.section-04-services {
    padding: $ratio12 0;

    .wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: $ratio4;

        .right {
            padding: $ratio8;
            background-color: $color-secondary;
            @extend .flex;
            @extend .flex-column;
            @extend .justify-center;
            gap: $ratio8;

            h3 {
                padding-bottom: $ratio4;
            }

            .btn {
                margin-top: $ratio4;
            }
        }
    }

    @media screen and (max-width: $size-md-min) {
        .wrapper {
            grid-template-columns: 1fr;

            .left {
                order: 2;
            }

            .right {
                order: 1;
            }
        }
    }
}

.section-05-services {
    padding: $ratio12 0;

    .wrapper {
        display: grid;
        grid-template-columns: 4fr 6fr;
        gap: $ratio4;

        .left {
            padding: $ratio8;
            background-color: $color-secondary;
            @extend .flex;
            @extend .flex-column;
            @extend .justify-center;
            gap: $ratio8;
        }
    }

    @media screen and (max-width: $size-md-min) {
        .wrapper {
            grid-template-columns: 1fr;
        }
    }
}

.section-06-services {
    padding: $ratio12 0;

    .wrapper {
        position: relative;

        h1 {
            padding: $ratio8 $ratio10;
            background-color: $color-primary;
            font-size: $font-size-40;
            color: $color-white;

            position: absolute;
            right: 0;
            bottom: 0;
        }
    }

    @media screen and (max-width: 480px) {
        .wrapper {
            h1 {
                position: relative;
                padding: $ratio5 $ratio8;
                width: auto;
            }
        }
    }
}

.section-07-services {
    padding: $ratio10 0;

    .wrapper {
        background-color: $color-secondary;
        
        .content {
            padding: $ratio10 $ratio12;
            
            ul {
                padding: $ratio8;
                border: solid 1px #707070;
                list-style: none;
                @extend .flex;
                @extend .flex-column;
                gap: $ratio8;

                li {
                    @extend .flex;
                    @extend .items-center;
                    gap: $ratio6;

                    h2 {
                        font-size: $font-size-30;
                        font-weight: 400;
                    }
                }
            }

            .circle {
                background-color: $color-black;
                height: $ratio5;
                width: $ratio5;
                min-width: $ratio5;
                border-radius: 50%;
            }
        }
    }

    @media screen and (max-width: $size-xs-max) {
        .wrapper {
            .content {
                padding: $ratio8 $ratio10;

                ul {
                    padding: $ratio6;

                    li {
                        h2 {
                            font-size: $font-size-24;
                        }
                    }
                }

                .circle {
                    height: $ratio3;
                    width: $ratio3;
                    min-width: $ratio3;
                }
            }
        }
    }
}

/*****************************************************/
/* PRIX
/*****************************************************/

.section-02-prix {
    padding: $ratio12 0;

    .wrapper {
        .top-content {
            @extend .flex;
            @extend .items-center;
            gap: $ratio12;

            h2 {
                font-weight: 400;
            }

            .line {
                background-color: $color-black;
                height: 100px;
                width: 3px;
            }
        }
    }

    @media screen and (max-width: $size-md-min) {
        .wrapper {
            .top-content {
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -webkit-flex-direction: column;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-align-items: flex-start;
	            align-items: flex-start;
                gap: $ratio6;

                .line {
                    height: 1px;
                    width: 100%;
                }
            }
        }
    }
}


/*****************************************************/
/* COORDONNEES
/*****************************************************/

.section-01-coordonnees {
    padding-top: $ratio12;

    .wrapper {
        padding-right: $ratio4;

        .infos {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: $ratio12;

            .info {
                h3 {
                    padding-bottom: $ratio6;
                    font-size: $font-size-24;
                    color: $color-black;
                    text-transform: uppercase;
                }
                a {
                    font-size: 16px;
                    color: #909090;
                    line-height: 1.4;
                }
            }
        }
    }

    @media screen and (max-width: $size-md-min) {
        .wrapper {
            padding: 0;

            .infos {
                grid-template-columns: 1fr 1fr;
            }
        }
    }

    @media screen and (max-width: $size-xs-max) {
        .wrapper {
            .infos {
                grid-template-columns: 1fr;

                .info:first-child {
                    height: 30px;
                }
            }
        }
    }
}

hr.spacer {
    margin: $ratio12 0;
    border: none;
    border-bottom: solid 1px #000;
}

.section-contact-form {
    .wrapper {
        display: grid;
        grid-template-columns: 6fr 4fr;
        gap: $ratio12;

        .schedule__box {
            .day_time {
                padding-top: $ratio4;
                @extend .flex;
                @extend .justify-between;
                @extend .items-center;
            }

            p.alert {
                padding-top: $ratio8;
                color: $color-primary;
            }
        }
    }

    @media screen and (max-width: $size-md-max) {
        .wrapper {
            grid-template-columns: 1fr 1fr;
        }
    }

    @media screen and (max-width: $size-md-mid) {
        .wrapper {
            grid-template-columns: 1fr;

            .container {
                order: 2;
            }

            .schedule__box {
                order: 1;
            }
        }
    }
}

.section-googlemap {
    padding: $ratio12 0;
}