.btn {
    width: fit-content;
    padding: $ratio5;
    font-size: 16px;
    background-color: $color-primary;
    color: $color-white;
    text-transform: uppercase;

    @extend .flex;
    @extend .justify-center;
    @extend .items-center;
    gap: $ratio2;

    transition: all 0.3s ease;

    img {
        transition: all 0.3s ease;
        opacity: 0;
        transform: translateX(-20%);
    }

    &:hover {
        img {
            opacity: 1;
            transform: translateX(10%);
        }
    }
}

.btn-submit {

    button {
        border: none;
        cursor: pointer;
    }
}
    

.captcha {
    visibility: hidden;
}