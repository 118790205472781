.accordions {
    padding-top: $ratio12;
    @extend .flex;
    @extend .flex-column;
    gap: $ratio6;

    .accordion__container {
        padding: $ratio4 $ratio6;
        background-color: $color-secondary;
        @extend .flex;
        @extend .flex-column;
        gap: $ratio8;
        transition: all 0.6s ease;

        .accordion__header {
            @extend .flex;
            @extend .justify-between;

            .accordion__toggle {
                $bar-height: 3px;
                $bar-width: 25px;
                $bar-spacer: 5px;

                padding-top: $ratio8;
                background-color: transparent;
                border: none;
                outline: none;
                cursor: pointer;
                position: relative;
                height: $bar-height;
                width: $bar-width;

                span {
                    background-color: $color-black;
                    height: $bar-height;
                    min-width: $bar-width;
                    width: 100%;
                    position: absolute;
                    top: calc(50% - #{$bar-height} / 2);
                    left: calc(50% - #{$bar-width} / 2);
                    transition: all 0.3s ease;

                    &:nth-child(1) {
                        transform: rotate(90deg);
                    }
    
                    &:nth-child(2) {
                        transform: rotate(-180deg);
                    }
                }
            }
        }

        .accordion__content {
            display: none;

            .titles,
            .product-infos {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                gap: $ratio4;

                .fullspecs {
                    display: block;
                }

                .specs {
                    display: none;
                }
            }
            
            h3 {
                font-size: $font-size-20;
            }

            hr {
                margin: $ratio2 0;
            }

            .value {
                font-size: $font-size-16;

                p {
                    font-size: $font-size-16;
                }
            }

            &.is-opened {
                max-height: fit-content;
                @extend .flex;
                @extend .flex-column;
                gap: $ratio4;
            }

        }

        &.is-opened {
            .accordion__toggle {
                $bar-height: 3px;

                span {
                    top: calc(50% - #{$bar-height} / 2);
    
                    &:nth-child(1) {
                        transform: rotate(360deg);
                    }
                    &:nth-child(2) {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }

    @media screen and (max-width: $size-xs-max) {
        .accordion__header {
            .accordion__toggle {
                transform: scale(0.8);
            }
        }

        .accordion__container {
            .accordion__content {
                .titles {
                    .fullspecs {
                        display: none;
                    }
        
                    .specs {
                        display: block;
                    }
                }
            } 
        }          
    }
}