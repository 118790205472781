/*
    Where we set the typography of the template
 */

// sets default font for the website and links
body {
    font-family: $font-family-1;
    line-height: 1;

    a {
        text-decoration: none !important;
        display: inline-block;

        &:hover, &:focus {
            text-decoration: none !important;
        }
    }
}

h1, h2, h3, h4, h5, h6 {
    font-family: $font-family-1;
    line-height: 1.4;
}

h3 {
    font-size: $font-size-24;
}

p {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.4;
}

