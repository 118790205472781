/*****************************************************/
// MENU
/* needs _sources/js/main.js to toggle var menu = ".menu-mobile"; */

nav.module-menu {
    height: $size-nav-height;
    position: relative;
    z-index: $z-index-menu;

    /****************************************************
    /* Set if the menu is over the slide show or not
    */
    // if menu do NOT overlap the content
    $menu-bottom-padding-desktop : $size-nav-height;
    $menu-bottom-padding-mobile : $size-nav-height-mobile;
    // if menu DO overlap over the content
    $menu-bottom-padding-desktop : 0;
    $menu-bottom-padding-mobile : 0;
    // sets the padding according to context
    // padding-bottom: $menu-bottom-padding-desktop;
    // @media screen and (max-width: $size-nav-toggle-breakpoint) {
    //     padding-bottom: $menu-bottom-padding-mobile;
    // }

    /************ menu desktop ***************************/
    .menu-navbar {
        @extend .flex;
        @extend .flex-column;
        position: fixed;
        top: 0;
        width: 100%;
        height: $size-nav-height;
        transition: all $transition-speed-fast ease;
        z-index: $z-index-menu + 1;

        /* purgecss start ignore */
        &.nav-invisible {
            top: -$size-nav-height;
            transition: all $transition-speed-fast ease;
        }

        @media screen and (max-width: $size-nav-shift-breakpoint) {
            height: $size-nav-height-mobile;
            transition: all $transition-speed-fast ease;
            &.nav-invisible {
                top: -$size-nav-height-mobile;
                transition: all $transition-speed-fast ease;
            }
            &.nav-visible {
                top: 0;
                transition: all $transition-speed-fast ease;
            }
        }
        /* purgecss end ignore */

        .wrapper {
            background-color: $color-black;
            padding: 45px 7.5% 0 7.5%;
            margin: 0 auto;
            position: relative;
            height: 100%;
            z-index: $z-index-menu + 2;
            @extend .flex;
            @extend .flex-column;
            @extend .justify-center;


            .main-menu {
                position: relative;
                z-index: $z-index-menu + 3;
                width: 100%;
                @extend .flex;
                @extend .justify-between;
                @extend .items-center;

                
                @media screen and (max-width: $size-md-max) {
                    .logo {
                        a > img {
                            height: 30px;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: $size-md-max) {
        height: $size-footer-width-mobile;
    }

    /************ menu toggle ***************************/
    .menu-toggle {    
        @extend .flex;
        @extend .items-center;
        gap: $ratio4;
        cursor: pointer;
        z-index: $z-index-menu + 4;

        .burger {
            --bar-height: 4px;
            --bar-width: 40px;
            --bar-spacer: 6px;
    
            background-color: transparent;
            border: none;
            outline: none;   
            padding: 0;
            position: relative;
            width: var(--bar-width);
            height: var(--bar-width);
            z-index: 100;
            
            span {
                background-color: $color-primary;
                height: var(--bar-height);
                width: 100%;
                position: absolute;
                top: calc(50% - var(--bar-height) / 2);
                left: calc(50% - var(--bar-width) / 2);
                transition: all 0.3s ease-out;
    
                &:nth-child(1) {
                    top: calc(
                        50% - var(--bar-height) / 2 - var(--bar-height) -
                            var(--bar-spacer)
                    );
                }
                &:nth-child(3) {
                    top: calc(
                        50% - var(--bar-height) / 2 + var(--bar-height) +
                            var(--bar-spacer)
                    );
                }
            }
        }

        &.toggled {
            .burger {               
                span {
                    top: calc(50% - var(--bar-height) / 2);
        
                    &:nth-child(1) {
                        transform: rotate(45deg);
                    }
                    &:nth-child(2) {
                        transform: scaleX(0);
                    }
                    &:nth-child(3) {
                        transform: rotate(-45deg);
                    }
                }    
            }
        }
    }
}

// display mobile menu, hide desktop

nav.module-menu {
    ul {      
        @extend .flex;
        gap: $ratio10;
        list-style: none;

        transition: all 0.6s ease;

        li.item {
            a {
                padding: $ratio4 $ratio3;
                font-size: 16px;
                background-color: transparent;
                color: #A6ADB4;
                text-transform: uppercase;
                transition: all 0.3s ease;
            }

            &:hover {
                a {
                    color: $color-white;
                    background-color: $color-primary;
                }
            }
        }

        li.item.active {
            a {
                color: $color-white;
                background-color: $color-primary;
            }
        }
    }

    .left-nav {
        @extend .flex;
    }

    .menu-toggle {
        display: none;
    }
    
    .menu-mobile {
        padding: $size-nav-height 7.5%;
        z-index: -1;
        background-color: $color-grey;
        height: 100%;
        width: 100%;
        position: fixed;
        @extend .flex-column;

        transform: translateY(-100%);
        transition: all 0.6s ease;

        li.item {
            a {
                color: $color-white;
            }
        }

        &.toggled {
            transform: translateY(0);
        }
    }

    @media screen and (max-width: $size-md-min) {
        .left-nav {
            .menu {
                display: none;
            }
        }
        .menu-toggle {
            display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
            display: -webkit-flex;
            display: -moz-flex;
            display: -ms-flex;
            display: -o-flex;
            display: flex;
        }

    }
}


.bar-top {
    position: fixed;
    height: 30px;
    width: 100%;
    padding: $ratio2 0;
    background-color: $color-tertiary;
    z-index: $z-index-menu + 5;

    .wrapper-phone {
        margin: 0 auto;
        max-width: $size-lg-max;
        width: calc(100% - 15%);
        height: 100%;
        @extend .flex;
        @extend .justify-end;

        a {
            @extend .flex; 
            @extend .items-center;
            gap: $ratio2;
            color: $color-grey-light;

            img {
                height: 16px;
                width: 16px;
            }
        }

        .phone-link {
            font-size: 14px;
            font-weight: 700;
            color: $color-white;
        }
    }
}
